import { useState, useEffect } from "react";
import { getUser } from "@/api/data";

export function useUser() {
  const [user, setUser] = useState(null);
  const [org, setOrg] = useState(null);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    async function fetchUser() {
      const { user, org } = await getUser();
      setUser(user);
      setOrg(org);
      setLoading(false);
    }

    fetchUser();
  }, []);

  return { user, org, loading };
}
