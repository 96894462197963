"use client";

import { useUser } from "@/api/hooks";
import Button from "@/components/Button";
import Link from "@/components/Link";
import IconBox from "@/components/IconBox";

function NavigationLink({ icon, label, href }) {
  return (
    <Link
      href={href}
      className="flex items-center gap-3 px-3 py-2 font-medium opacity-80 hover:opacity-100 flex-shrink-0"
    >
      <div className="flex flex-col text-base text-right whitespace-nowrap">
        {label}
      </div>
      <IconBox icon={icon} color="purple" size="lg" />
    </Link>
  );
}

export default function NavigationAccount({
  account = false,
  dashboard = false,
}) {
  const { user, loading } = useUser();
  if (loading) return <p>Loading...</p>;
  if (user) {
    return (
      <div className="flex items-center">
        {dashboard ? (
          <NavigationLink
            icon="dashboard"
            label="Dashboard"
            href="/dashboard"
          />
        ) : null}
        {account ? (
          <NavigationLink icon="account" label={user.email} href="/account" />
        ) : null}
      </div>
    );
  }
  return (
    <div className="flex items-center space-x-4">
      <Button href="/login" label="Log In" className="hidden sm:block" />
      <Button href="/register" label="Create Account" primary />
    </div>
  );
}
