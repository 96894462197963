import Link from "@/components/Link";

export default function NavigationLogo({ href = "/" }) {
  return (
    <Link
      block
      href={href}
      className="flex items-center py-2 opacity-90 hover:opacity-100 w-24 lg:w-36 flex-shrink-0"
    >
      <img
        src="https://ittybit-app.ittybitcdn.com/med_497obJ50a6oWP8F9/ittybit-logo_2x.png"
        width="300"
        height="100"
        className={`relative h-8 lg:h-12 w-auto top-1 antialiased`}
        alt="ittybit"
        fetchPriority="high"
      />
    </Link>
  );
}
