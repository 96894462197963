"use client";

import NavigationLogo from "@/components/NavigationLogo";

export default function Navigation({ children }) {
  return (
    <nav className="bg-dark-500 sticky top-0 left-0 right-0 w-full flex items-center justify-start px-4 md:px-8 lg:px-12 z-50">
      <NavigationLogo />
      {children}
    </nav>
  );
}
